import React from 'react';
import styled from '@emotion/styled';
import { graphql } from 'gatsby';
import {
  A,
  Page,
  SEO,
  H4,
  P,
  Tag,
  spacings,
  fonts,
  responsiveFont,
} from '../../../../packages/shared';

export const query = graphql`
  query {
    allMdx(sort: { fields: frontmatter___title, order: ASC }) {
      totalCount
      edges {
        node {
          id
          fields {
            slug
          }
          frontmatter {
            title
            date(formatString: "ll")
            author
            tags
            slug
          }
          excerpt
        }
      }
    }
    site {
      siteMetadata {
        title
        description
        author
        siteUrl
      }
    }
  }
`;

const PageContent = styled('div')``;

const PageSection = styled('div')`
  margin-bottom: ${spacings.section};
`;

const EntryWrapper = styled('div')``;

const SectionHeader = styled(Tag)`
  ${fonts.bold}
  ${responsiveFont(1.25, 1.5)}
  font-variant: small-caps;
  text-transform: lowercase;
`;

const PageSections = [
  'baked',
  'pasta',
  'asian',
  'main',
  'roast',
  'side',
  'soup',
  'condiment',
  'misc',
];

const IndexPage = ({ data }) => {
  const recipesBySection = {};

  PageSections.forEach((tag) => {
    recipesBySection[tag] = data.allMdx.edges.filter((edge) =>
      edge.node.frontmatter.tags.includes(tag)
    );
  });

  return (
    <Page>
      <SEO title="Erica's Recipes" siteData={data.site} />

      <PageContent>
        <PageSection>
          <H4>{`${data.allMdx.edges.length} Recipes`}</H4>
        </PageSection>

        {Object.keys(recipesBySection).map((tag, i) => {
          const recipeLength = recipesBySection[tag].length;

          return (
            <PageSection key={i}>
              <SectionHeader>{`${tag} (${recipeLength} recipe${
                recipeLength === 1 ? '' : 's'
              })`}</SectionHeader>

              {recipesBySection[tag].map(({ node }) => {
                const { id, excerpt, fields, frontmatter } = node;

                return (
                  <EntryWrapper key={id}>
                    <H4>
                      <A href={`${fields.slug}`}>{frontmatter.title}</A>
                    </H4>
                    <div>
                      <Tag>{frontmatter.tags.join(', ')}</Tag>
                    </div>
                    <P>{excerpt}</P>
                  </EntryWrapper>
                );
              })}
            </PageSection>
          );
        })}
      </PageContent>
    </Page>
  );
};

export default IndexPage;
